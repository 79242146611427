<template>
  <div v-if="shouldRenderComponent"
       class="bx-content bx-vod-epg-broadcast-slider">
    <TabContainer :header-border-visible="true">
      <Tab v-if="shouldRenderTrailers"
           title="TRAILER">
        <template v-if="shouldRenderSingleTrailer">
          <ParagraphTypeEmbed :embed="trailerItems[0].embed"
                              :vendor-id="rsConfig.consent.vendor.youtube" />
        </template>
        <template v-else>
          <ParagraphTypeSlider :brand="brand"
                               :gallery-items="transformedTrailerItems"
                               :single-slide-urls="false"
                               :is-preview="isPreview" />
        </template>
      </Tab>
      <Tab v-if="shouldRenderImages"
           title="BILDER">
        <template v-if="shouldRenderSingleImage">
          <ParagraphTypeImage v-if="imageItems[0].paragraphType === 'image'"
                              :image="imageItems[0].image"
                              :brand="brand"
                              :loading="getLoadingValue(0)" />
          <ParagraphTypeTmdbImage v-else-if="imageItems[0].paragraphType === 'tmdbImage'"
                                  :image="imageItems[0].tmdbImage"
                                  :loading="getLoadingValue(0)" />
        </template>
        <template v-else>
          <ParagraphTypeSlider :brand="brand"
                               :gallery-items="transformedImageItems"
                               :single-slide-urls="false"
                               :is-preview="isPreview" />
        </template>
      </Tab>
    </TabContainer>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../stores/config'
import { TabContainer, Tab } from '../shared/tabs'
import ParagraphTypeSlider from './ParagraphTypeSlider.vue'
import ParagraphTypeEmbed from './ParagraphTypeEmbed.vue'
import ParagraphTypeImage from './ParagraphTypeImage.vue'
import ParagraphTypeTmdbImage from './ParagraphTypeTmdbImage.vue'
import imgSrcSet from '../../mixins/img-src-set'

export default {
  name: 'ParagraphTypeVodEpgBroadcastSlider',
  components: {
    TabContainer,
    Tab,
    ParagraphTypeSlider,
    ParagraphTypeEmbed,
    ParagraphTypeImage,
    ParagraphTypeTmdbImage
  },
  mixins: [imgSrcSet],
  props: {
    trailerItems: {
      type: Array,
      default: () => []
    },
    imageItems: {
      type: Array,
      default: () => []
    },
    brand: {
      type: String,
      default: null
    },
    // passed through from the-gui `ParagraphsPreview.vue` to rendering `ParagraphTypeSlider.vue`
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    transformedTrailerItems () {
      return this.trailerItems.map(({ paragraphType, embed }) => ({
        paragraphType,
        embed: {
          ...embed,
          vendorId: this.rsConfig.consent.vendor.youtube
        }
      }))
    },
    transformedImageItems () {
      return this.imageItems.map((item) => ({
        paragraphType: item.paragraphType,
        [item.paragraphType]: item[item.paragraphType]
      }))
    },
    shouldRenderComponent () {
      return this.imageItems.length !== 0 || this.trailerItems.length !== 0
    },
    shouldRenderTrailers () {
      return this.trailerItems.length !== 0
    },
    shouldRenderImages () {
      return this.imageItems.length !== 0
    },
    shouldRenderSingleTrailer () {
      return this.trailerItems.length === 1
    },
    shouldRenderSingleImage () {
      return this.imageItems.length === 1
    }
  },
  methods: {
    getLoadingValue (index) {
      return index === 0 ? 'eager' : 'lazy'
    }
  }
}
</script>
